.lightning-report-container {
    font-family: 'Poppins', Arial, sans-serif;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    color: #333;
}

h2 {
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    margin-top: 30px;
    font-weight: 600;
    color: #2c3e50;
    letter-spacing: 1px;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
}

.report-grid {
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
}

.report {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
    border-radius: 15px;
    text-align: left;
    background: rgba(255, 255, 255, 0.3);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    animation: fadeInUp 0.8s ease-out;
    animation-fill-mode: both;
    text-decoration: none !important;
    max-width: 300px;
    margin-left: 0;
}

.report::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(135deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0.3) 100%);
    opacity: 0;
    transition: opacity 0.3s ease;
}

.report:hover::before {
    opacity: 1;
}

.report-icon {
    font-size: 36px;
    margin-bottom: 15px;
    transition: transform 0.3s ease;
}

.report:hover .report-icon {
    transform: scale(1.1);
}

.report-title {
    font-weight: bold;
    margin-bottom: 8px;
    font-size: 16px;
    color: #2c3e50;
    transition: color 0.3s ease;
}

.report:hover .report-title {
    color: #3498db;
}

.report-read-more {
    font-size: 12px;
    margin-top: 8px;
    color: #3498db;
    font-weight: bold;
}

.report:hover .report-read-more,
.report:focus .report-read-more,
.report:active .report-read-more {
    text-decoration: none !important;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@media (max-width: 768px) {
    .lightning-report-container {
        padding: 10px;
    }

    h2 {
        font-size: 24px;
        margin-top: 20px;
    }

    .report {
        padding: 12px;
        max-width: 250px;
    }

    .report-icon {
        font-size: 32px;
        margin-bottom: 12px;
    }

    .report-title {
        font-size: 14px;
    }

    .report-read-more {
        font-size: 11px;
    }
}

@media (max-width: 480px) {
    .lightning-report-container {
        padding: 5px;
    }

    h2 {
        font-size: 20px;
        margin-top: 15px;
    }

    .report {
        padding: 10px;
        max-width: 200px;
    }

    .report-icon {
        font-size: 28px;
        margin-bottom: 8px;
    }

    .report-title {
        font-size: 12px;
    }

    .report-read-more {
        font-size: 10px;
    }
}