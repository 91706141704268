.contact-container {
    background: #f4f6f9;
    border-radius: 12px;
    padding: 50px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 950px;
    margin: 50px auto;
}

h2 {
    font-size: 36px;
    font-weight: 700;
    color: #2c3e50;
    margin-bottom: 20px;
}

.contact-subtitle {
    font-size: 18px;
    color: #7f8c8d;
    margin-bottom: 40px;
}

.form-group {
    margin-bottom: 25px;
}

.form-control {
    border-radius: 6px;
    height: 50px;
    font-size: 16px;
    border: 1px solid #dce1e5;
    padding: 15px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.05);
    transition: border-color 0.3s ease;
}

.form-control:focus {
    border-color: #db3434;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

textarea.form-control {
    height: auto;
    resize: none;
}

.btn-primary {
    background-color: #b10404;
    border-color: #b10404;
    width: 100%;
    font-size: 18px;
    padding: 15px;
    border-radius: 6px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
}

.btn-primary:hover {
    background-color: #bd2121;
}

.map-container {
    border-radius: 12px;
    border: 1px solid #dce1e5;
    width: 100%;
    height: 350px;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin-top: 30px;
}

.contact-info {
    margin-top: 40px;
}

.contact-info-bar {
    background-color: #ecf0f1;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}

.contact-info-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    padding: 15px;
    border-radius: 8px;
    transition: all 0.3s ease;
}

.contact-info-item:hover {
    transform: scale(1.05);
    background-color: #f8f9fa;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-icon {
    font-size: 28px; /* Increased from 24px */
    color: #b92929;
    margin-right: 15px;
    flex-shrink: 0;
    transition: color 0.3s ease;
}

.contact-info-item:hover .contact-icon {
    color: #db3434;
}

.contact-info-item div {
    display: flex;
    flex-direction: column;
}

.contact-info-item strong {
    font-size: 18px; /* Increased from 16px */
    color: #2c3e50;
    margin-bottom: 5px;
    transition: color 0.3s ease;
}

.contact-info-item:hover strong {
    color: #1a252f;
}

.contact-info-item p {
    font-size: 16px; /* Increased from 14px */
    color: #000000;
    margin: 0;
    line-height: 1.5;
    transition: color 0.3s ease;
}

.contact-info-item:hover p {
    color: #34495e;
}

@media (max-width: 767px) {
    .contact-info-bar {
        padding: 20px;
        flex-direction: column;
    }

    .contact-info-item {
        margin-bottom: 15px;
        padding: 10px;
    }

    .contact-icon {
        font-size: 24px; /* Slightly increased from 20px for mobile */
        margin-right: 10px;
    }

    .contact-info-item strong {
        font-size: 16px; /* Increased from 14px for mobile */
    }

    .contact-info-item p {
        font-size: 14px; /* Increased from 13px for mobile */
    }
}

p {
    font-size: 16px;
    color: #2c3e50;
    margin: 0;
    line-height: 1.5;
}