/* src/components/Header.css */

.header {
  width: 100%;
  background-color: #f8f9fa;
  padding: 10px 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #ddd;
}

.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #2c3e50;
  color: white;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.contact-info {
  display: flex;
  flex-direction: column;
  font-size: 0.9em;
  color: #ecf0f1;
}

.contact-info a {
  color: #3498db;
  text-decoration: none;
  margin-left: 5px;
  transition: color 0.3s ease;
}

.contact-info a:hover {
  color: #2980b9;
  text-decoration: underline;
}

.social-icons {
  display: flex;
}

.social-icons a {
  color: white;
  margin-left: 15px;
  font-size: 1.2em;
  transition: color 0.3s ease;
  text-decoration: none;
}

.social-icons a:hover {
  color: #3498db;
}

.header-bottom {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 10px;
}

.logo {
  height: 60px;
  margin-right: 20px;
}

.title-news-weather-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.site-title-container {
  margin-left: 10px; /* Add some space between the logo and the title */
}

.site-title {
  font-size: 24px;
  font-weight: bold;
  color: #2c3e50;
  text-align: left;
}

.news-weather-container {
  display: flex;
  flex-direction: column; /* Stack news above weather */
  justify-content: space-between;
  width: 40%; /* Adjust width to 40% */
  margin-top: 10px;
}

.news-headline {
  width: 100%; /* Adjust width to 100% */
  margin-bottom: 10px; /* Add margin to separate from weather block */
  text-align: right; /* Align text to the right */
}

.weather-info {
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Align weather info to the right */
  width: 100%; /* Adjust width to 100% */
  padding-left: 10px;
}

.weather-details {
  text-align: right; /* Align text to the right */
  font-size: 0.8em; /* Make the text smaller */
}

.weather-icon {
  margin-left: 10px;
  width: 40px; /* Make the weather icon smaller */
  height: 40px; /* Make the weather icon smaller */
}

/* RSS feed section styling */
.rss-feed-container {
  margin-top: 10px;
}

.rss-feed {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  background-color: #e8f4f8;
  border: 2px solid #3498db;
  border-radius: 8px;
  color: #2c3e50;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.rss-feed-content {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  animation: scrollLeft 20s linear infinite;
}

.rss-feed-item {
  display: inline-block;
  margin: 0 15px;
  color: #3498db;
  text-decoration: none;
  transition: color 0.3s ease;
}

.rss-feed-item:hover {
  text-decoration: underline;
  color: #2980b9;
}

/* Keyframes for scroll animation */
@keyframes scrollLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.left-container {
  display: flex;
  align-items: center;
  width: 50%; /* Adjust width to 50% */
}

.site-motto {
  font-size: 0.9rem;
  color: #666;
  margin-top: 5px;
  font-style: italic;
}

/* Mobile-specific styles */
@media (max-width: 767px) {
  .header-top {
    flex-direction: column;
    align-items: flex-start;
    padding: 5px 10px;
  }

  .contact-info {
    font-size: 0.8em;
    margin-bottom: 5px;
  }

  .social-icons {
    align-self: flex-start;
  }

  .social-icons a {
    font-size: 1em;
    margin-left: 10px;
  }

  .header-bottom {
    flex-direction: column;
    align-items: center;
  }

  .left-container {
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }

  .logo {
    width: 80px;
    height: auto;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .site-title-container {
    text-align: center;
  }

  .site-title {
    font-size: 1.2em;
    text-align: center;
  }

  .site-motto {
    font-size: 0.8em;
    text-align: center;
  }

  .news-weather-container {
    width: 100%;
    margin-top: 10px;
  }

  .news-headline {
    width: 100%;
    margin-bottom: 10px;
    text-align: center;
  }

  .weather-info {
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .weather-details {
    text-align: center;
    font-size: 0.9em;
  }

  .weather-icon {
    margin-left: 0;
    margin-top: 5px;
    width: 40px;
    height: 40px;
  }

  .rss-feed-container {
    margin-top: 10px;
  }

  .rss-feed {
    font-size: 0.8em;
    padding: 5px;
  }
}

.latest-conference {
  margin-top: 10px;
  padding: 10px 15px;
  font-weight: bold;
  text-align: center;
}

.conference-content {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.new-badge {
  background-color: #28a745;
  color: white;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 0.8em;
  margin-right: 10px;
  animation: flash 1s infinite alternate;
}

.latest-conference a {
  color: #155724;
  text-decoration: none;
  flex: 1;
  text-align: center;
}

@keyframes flash {
  from { opacity: 1; }
  to { opacity: 0.5; }
}

/* Mobile-specific styles */
@media (max-width: 767px) {
  /* ... existing mobile styles ... */

  .latest-conference {
    padding: 5px;
  }

  .conference-content {
    flex-direction: column;
  }

  .new-badge {
    margin-bottom: 5px;
  }
}
