@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

/* Container Styles */
.lri-campaign-container {
  font-family: 'Poppins', sans-serif;
  max-width: 1200px;
  margin: 60px auto;
  padding: 40px;
  background-color: #f8f9fa;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.lri-campaign-title {
  font-size: 36px;
  font-weight: 700;
  color: #CC0100;
  text-align: center;
  margin-bottom: 40px;
  letter-spacing: 0.5px;
}

.lri-news-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 40px;
}

/* News Item Styles */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.lri-news-item {
  background-color: #fff;
  border-radius: 15px;
  overflow: hidden;
  transition: all 0.3s ease;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  animation: fadeInUp 0.6s ease-out forwards;
  opacity: 0;
}

.lri-news-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.lri-news-item:nth-child(2) {
  animation-delay: 0.2s;
}

.lri-news-item:nth-child(3) {
  animation-delay: 0.4s;
}

.lri-news-item:nth-child(4) {
  animation-delay: 0.6s;
}

/* Image Styles */
.lri-news-image-container {
  position: relative;
  height: 200px;
  overflow: hidden;
}

.lri-news-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.lri-news-item:hover .lri-news-image {
  transform: scale(1.05);
}

.lri-news-date {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 12px;
  color: #B71C1C;
}

/* Content Styles */
.lri-news-content {
  padding: 25px;
}

.lri-news-title {
  font-size: 24px;
  font-weight: 700;
  color: #B71C1C;
  margin-bottom: 15px;
  line-height: 1.3;
}

.lri-news-description {
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
  line-height: 1.6;
}

.lri-news-link {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background-color: #D32F2F;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 25px;
  transition: all 0.3s ease;
}

.lri-news-link:hover {
  background-color: #CC0100; /* Brighter red on hover */
  color: #fff;
  box-shadow: 0 5px 15px rgba(204, 1, 0, 0.3);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .lri-campaign-container {
    padding: 30px;
    margin: 40px 20px;
  }

  .lri-campaign-title {
    font-size: 28px;
    margin-bottom: 30px;
  }

  .lri-news-grid {
    grid-template-columns: 1fr;
    gap: 30px;
  }

  .lri-news-title {
    font-size: 22px;
  }

  .lri-news-description {
    font-size: 14px;
  }

  .lri-news-link {
    font-size: 14px;
    padding: 8px 16px;
  }
}
