/* src/components/About.css */

.about-container {
  padding: 20px 15px;
  background-color: #f8f8f8;
  max-width: 1200px;
  margin: 0 auto;
  animation: fadeIn 1s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.about-title {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: left;
  color: #333;
  margin-bottom: 20px;
  position: relative;
  animation: slideInLeft 1s ease-in-out;
}

@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.about-content {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.about-text {
  font-size: 1rem;
  line-height: 1.8;
  color: #333;
  text-align: justify;
  animation: fadeIn 1.2s ease-in-out;
}

.about-text a {
  color: #3498db;
  text-decoration: none;
}

.about-text a:hover {
  text-decoration: underline;
}

.about-left {
  flex: 1 0 25%; /* Occupy 25% of the width */
  padding: 20px;
  background-color: #fff; /* White background for a minimal look */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.about-section {
  margin-bottom: 20px; /* Reduced margin for a tighter look */
}

.about-section-title {
  font-size: 1.2rem; /* Slightly smaller font size */
  font-weight: bold;
  margin-bottom: 5px; /* Reduced margin */
  color: #cc0000; /* Red color for the titles */
  text-decoration: none; /* Remove default link underline */
  transition: color 0.3s ease; /* Smooth color transition on hover */
  display: block; /* Make the link a block element */
  padding: 10px 15px; /* Add padding for a box effect */
  border-radius: 5px; /* Rounded corners for the box */
  background-color: transparent; /* Default background color */
}

.about-section-title:hover {
  color: #fff; /* White text on hover */
  background-color: #cc0000; /* Red background on hover */
  text-decoration: none; /* Remove underline on hover */
}

/* Styles for the right side of the About section */
.about-right {
  flex: 1 0 75%; /* Occupy 75% of the width */
  padding: 30px; /* Increased padding for more space */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff; /* White background */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Enhanced shadow for depth */
  transition: box-shadow 0.3s ease-in-out;
}

.about-right:hover {
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

.about-right-content {
  margin-bottom: 30px; /* Space between content and image */
  transition: transform 0.3s ease-in-out;
}

.about-right:hover .about-right-content {
  transform: translateY(-5px);
}

.about-right h2 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
  border-bottom: 2px solid #cc0000;
  padding-bottom: 10px;
  transition: color 0.3s ease-in-out;
}

.about-right:hover h2 {
  color: #cc0000;
}

.about-right p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #555;
  margin-bottom: 15px;
  transition: color 0.3s ease-in-out;
}

.about-right:hover p {
  color: #333;
}

.about-right img {
  max-width: 100%; /* Ensure image is responsive */
  border-radius: 8px; /* Add rounded corners */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15); /* Enhanced shadow for the image */
  margin-top: 0; /* Remove top margin as we're using flexbox */
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.about-right:hover img {
  transform: scale(1.02);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.2);
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .about-content {
    flex-direction: column;
  }

  .about-left, .about-right {
    flex: 1 0 100%;
  }

  .about-right {
    margin-top: 20px; /* Add space between left and right sections on mobile */
    padding: 20px; /* Reduce padding on mobile */
  }

  .about-right h2 {
    font-size: 1.8rem; /* Slightly smaller heading on mobile */
  }

  .about-right p {
    font-size: 1rem; /* Slightly smaller text on mobile */
  }
}