.services-container {
    font-family: 'Poppins', Arial, sans-serif; /* Updated font */
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    color: #333; /* Updated text color */
}

h2 {
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    margin-top: 30px;
    font-weight: 600; /* Updated font weight */
    color: #2c3e50; /* Updated color */
    letter-spacing: 1px; /* Added letter spacing */
    text-shadow: 2px 2px 4px rgba(0,0,0,0.1); /* Added text shadow */
}

.services-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    margin-top: 20px;
}

.service {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 20px; /* Updated border radius */
    color: white;
    text-align: center;
    background: rgba(255, 255, 255, 0.3); /* Updated background */
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1); /* Added box shadow */
    transition: all 0.3s ease; /* Added transition */
    position: relative; /* Added position */
    overflow: hidden; /* Added overflow */
    animation: fadeInUp 0.8s ease-out; /* Added animation */
    animation-fill-mode: both; /* Added animation fill mode */
    text-decoration: none !important; /* Add !important to ensure it overrides any other styles */
}

.service::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(135deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0.3) 100%);
    opacity: 0;
    transition: opacity 0.3s ease;
}

.service:hover::before {
    opacity: 1;
}

.service-icon {
    font-size: 48px; /* Updated font size */
    margin-bottom: 20px; /* Updated margin */
    transition: transform 0.3s ease; /* Added transition */
}

.service:hover .service-icon {
    transform: scale(1.1); /* Added transform */
}

.service-title {
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 18px; /* Updated font size */
    color: #2c3e50; /* Updated color */
    transition: color 0.3s ease; /* Added transition */
}

.service:hover .service-title {
    color: #3498db; /* Updated hover color */
}

.service-read-more {
    font-size: 14px;
    margin-top: 10px;
    color: #3498db;
    font-weight: bold;
}

.service:hover .service-read-more,
.service:focus .service-read-more,
.service:active .service-read-more {
    text-decoration: none !important;
}

.forecast {
    background-color: #f8f9fa;
    padding: 15px 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    border: 1px solid #e9ecef;
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}

.forecast:hover {
    background-color: #e9ecef;
    transform: translateY(-3px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    text-decoration: none;
}

.forecast-icon {
    margin-right: 15px;
    color: #6c757d;
    font-size: 20px;
    transition: transform 0.3s ease;
}

.forecast:hover .forecast-icon {
    transform: scale(1.1);
    color: #3498db;
}

.forecast-title {
    font-size: 16px;
    font-weight: 500;
    color: #495057;
    transition: color 0.3s ease;
}

.forecast:hover .forecast-title {
    color: #212529;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.service:nth-child(1) { animation-delay: 0.1s; }
.service:nth-child(2) { animation-delay: 0.2s; }
.service:nth-child(3) { animation-delay: 0.3s; }
.service:nth-child(4) { animation-delay: 0.4s; }

@media (max-width: 768px) {
    .services-container {
        padding: 10px;
    }

    h2 {
        font-size: 24px;
        margin-top: 20px;
    }

    .services-grid {
        grid-template-columns: 1fr;
        gap: 15px;
    }

    .service {
        padding: 15px;
    }

    .service-icon {
        font-size: 36px;
        margin-bottom: 15px;
    }

    .service-title {
        font-size: 16px;
    }

    .service-read-more {
        font-size: 12px;
    }

    .forecasts-grid {
        grid-template-columns: 1fr;
        gap: 10px;
    }

    .forecast {
        padding: 12px 15px;
    }

    .forecast-icon {
        font-size: 18px;
        margin-right: 10px;
    }

    .forecast-title {
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .services-container {
        padding: 5px;
    }

    h2 {
        font-size: 20px;
        margin-top: 15px;
    }

    .service-icon {
        font-size: 32px;
        margin-bottom: 10px;
    }

    .service-title {
        font-size: 14px;
    }

    .service-read-more {
        font-size: 11px;
    }

    .forecast {
        padding: 10px;
    }

    .forecast-icon {
        font-size: 16px;
        margin-right: 8px;
    }

    .forecast-title {
        font-size: 12px;
    }
}