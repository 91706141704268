/* src/components/Navbar.css */
.navbar {
    background-color: #1da3d8; /* Set background color to white */
    padding: 10px; /* Optional: Adjust padding if needed */
    overflow: hidden; /* Hide content that overflows */
  }
  
  .navbar-nav {
    list-style: none;
    display: flex;
    justify-content: space-around;
    margin: 0;
    padding: 0;
    transition: transform 0.5s ease-in-out; /* Add transition for smooth animation */
  }
  
  .navbar-nav .nav-item {
    margin: 0 10px;
  }
  
  .navbar-nav .nav-link {
    text-decoration: none;
    font-weight: bold;
    color: white; /* Text color black */
    padding: 10px 15px;
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effect */
  }
  
  .navbar-nav .nav-link:hover {
    background-color: #cc0000; /* Hover background color */
    border-radius: 4px; /* Rounded corners for hover state */
    color: white; /* Change text color on hover */
  }
  
  .navbar-nav .nav-link.active {
    background-color: #cc0000; /* Active link background color */
    border-radius: 4px; /* Rounded corners for active state */
    color: white; /* Change text color for active state */
  }
  
  /* Add these styles for the sliding animation */
  .navbar-nav.slide-left {
    transform: translateX(-100%); /* Move the navbar off-screen to the left */
    animation: slideLeft 0.5s ease-in-out forwards; /* Add animation */
  }
  
  .navbar-nav.slide-right {
    transform: translateX(100%); /* Move the navbar off-screen to the right */
    animation: slideRight 0.5s ease-in-out forwards; /* Add animation */
  }
  
  /* Animations */
  @keyframes slideLeft {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  @keyframes slideRight {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  /* Style for the news headline */
  .news-headline {
    text-align: center;
    margin-top: 10px;
  }

  /* Mobile view adjustments */
  @media (max-width: 768px) {
    .navbar {
      padding: 5px;
    }

    .navbar-nav {
      flex-direction: column;
      align-items: center;
    }

    .navbar-nav .nav-item {
      margin: 10px 0; /* Increased margin for better spacing */
    }

    .navbar-nav .nav-link {
      padding: 12px 20px; /* Increased padding for better touch targets */
      font-size: 16px; /* Increased font size for readability */
    }

    .news-headline {
      width: 100%; /* Make the news headline cover 100% width */
      margin-top: 20px; /* Add margin for spacing */
    }

    .weather-section {
      width: 100%; /* Make the weather section cover 100% width */
      margin-top: 20px; /* Add margin for spacing */
    }
  }
