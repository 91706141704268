.vision-container {
  display: flex;
  flex-direction: column; /* Align items vertically */
  padding: 20px; /* Optional: Add some padding */
}

.vision-text {
  max-width: 600px; /* Optional: Limit the width of the text block */
  text-align: left; /* Align text to the left */
  margin-left: 20px; /* Add left margin */
  /* Add any other styling you need */
}