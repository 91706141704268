@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


.important-links-container {
  padding: 40px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 30px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  max-width: 1200px;
  width: 100%;
  margin: 40px auto;
  color: #333;
  border: 1px solid rgba(255, 255, 255, 0.3);
  animation: fadeInUp 0.8s ease-out;
}

.important-links-title {
  text-align: center;
  font-size: 36px;
  margin-bottom: 40px;
  font-weight: 600;
  color: #2c3e50;
  letter-spacing: 1px;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
}

.links-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  justify-content: center;
}

.link-card {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 20px;
  text-decoration: none;
  color: #333;
  font-weight: 500;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  overflow: hidden;
  position: relative;
  animation: fadeInUp 0.8s ease-out;
  animation-fill-mode: both;
}

.link-content {
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  transition: transform 0.3s ease;
}

.link-card:hover .link-content {
  transform: translateY(-5px);
}

.link-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0.3) 100%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.link-card:hover::before {
  opacity: 1;
}

.link-icon {
  font-size: 48px;
  margin-bottom: 20px;
  color: #3498db;
  transition: transform 0.3s ease;
}

.link-card:hover .link-icon {
  transform: scale(1.1);
}

.link-text {
  font-size: 18px;
  font-weight: 600;
  color: #2c3e50;
  text-align: center;
  transition: color 0.3s ease;
}

.link-card:hover .link-text {
  color: #3498db;
}

@media (max-width: 768px) {
  .important-links-container {
    padding: 30px;
  }
  
  .important-links-title {
    font-size: 28px;
  }
  
  .links-grid {
    gap: 20px;
  }
}

@media (max-width: 480px) {
  .important-links-container {
    padding: 20px;
  }
  
  .important-links-title {
    font-size: 24px;
  }
  
  .link-icon {
    font-size: 36px;
  }
  
  .link-text {
    font-size: 16px;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


.link-card:nth-child(1) { animation-delay: 0.1s; }
.link-card:nth-child(2) { animation-delay: 0.2s; }
.link-card:nth-child(3) { animation-delay: 0.3s; }
.link-card:nth-child(4) { animation-delay: 0.4s; }
