.about-container {
  padding: 20px 15px;
  background-color: #f8f8f8;
  max-width: 1200px;
  margin: 0 auto;
  animation: fadeIn 1s ease-out;
  /* Remove top margin */
  margin-top: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.about-title {
  font-size: 2rem;
  font-weight: bold;
  text-align: left;
  color: #333;
  margin-bottom: 20px;
  position: relative;
  animation: slideInLeft 1s ease-in-out;
}

@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.about-content {
  display: flex;
  flex-wrap: wrap;
}

.about-text {
  font-size: 1rem;
  line-height: 1.5;
  color: #333;
  text-align: justify;
  animation: fadeIn 1.2s ease-in-out;
}

.about-text a {
  color: #3498db;
  text-decoration: none;
}

.about-text a:hover {
  text-decoration: underline;
}

.carousel-slide {
  margin-top: 20px;
  animation: fadeIn 1.4s ease-in-out;
  margin-left: 10px; /* Added left margin */
}

.carousel-inner {
  overflow: hidden;
  position: relative;
  border-radius: 8px; /* Rounded corners for the image container */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Shadow for the image container */
}

.carousel-image {
  max-height: 500px;
  object-fit: cover;
  transition: transform 0.5s ease-in-out, filter 0.5s ease-in-out;
  filter: brightness(90%);
  border-radius: 8px; /* Rounded corners for images */
}

.carousel-image:hover {
  transform: scale(1.05);
  filter: brightness(100%);
}

.carousel-caption {
  background: rgba(0, 0, 0, 0.6);
  padding: 15px;
  animation: fadeIn 1.5s ease-in-out;
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  border-radius: 8px; /* Rounded corners for the caption */
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  
  border-radius: 50%;
  padding: 15px;
  transition: background-color 0.3s ease;
}

.carousel-control-prev-icon:hover,
.carousel-control-next-icon:hover {

}
