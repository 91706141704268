@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

.footer-container {
  background: linear-gradient(135deg, #1c2833 0%, #2c3e50 100%);
  color: #f8f9fa;
  padding: 50px 20px;
  font-family: 'Poppins', sans-serif;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-column {
  flex: 1;
  min-width: 250px;
  padding: 15px;
}

.footer-header {
  font-size: 22px;
  font-weight: 600;
  color: #ecf0f1;
  margin-bottom: 20px;
  border-bottom: 2px solid #ecf0f1;
  padding-bottom: 10px;
}

.footer-text {
  font-size: 14px;
  line-height: 1.8;
  color: #bdc3c7;
  margin-bottom: 15px;
}

.footer-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-links li {
  margin-bottom: 12px;
}

.footer-links a {
  color: #bdc3c7;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
  display: inline-flex;
  align-items: center;
}

.footer-links a:hover {
  color: #3498db;
}

.footer-social {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.social-icon {
  display: inline-block;
  background-color: #3498db;
  color: #fff;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  margin: 0 10px;
  transition: background-color 0.3s ease;
}

.social-icon:hover {
  background-color: #2980b9;
}

.footer-bottom {
  text-align: center;
  padding: 20px;
  border-top: 1px solid #bdc3c7;
  margin-top: 30px;
}

.footer-bottom p {
  margin: 0;
  font-size: 14px;
  color: #bdc3c7;
}

.footer-design {
  color: #ecf0f1;
  font-weight: 600;
}

.android-icon,
.apple-icon {
  margin-left: 8px;
  color: #bdc3c7;
}

.footer-links a > span {
  display: inline-flex;
  align-items: center;
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    text-align: center;
  }

  .footer-column {
    padding: 20px 0;
  }

  .social-icon {
    margin: 0 5px;
  }

  .footer-social {
    justify-content: center;
  }
}

/* Additional Mobile Optimizations */
@media (max-width: 480px) {
  .footer-container {
    padding: 30px 15px;
  }

  .footer-column {
    min-width: 100%;
  }

  .footer-header {
    font-size: 20px;
  }

  .footer-text,
  .footer-links a,
  .footer-bottom p {
    font-size: 13px;
  }

  .footer-social {
    justify-content: center;
    flex-wrap: wrap;
  }

  .social-icon {
    width: 30px;
    height: 30px;
    line-height: 30px;
    margin: 5px;
  }

  .footer-bottom {
    padding: 15px 0;
  }
}
