body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #f8f8f8;
}

.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0;
}

.carousel-container {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
  margin-top: 2%;
}

.carousel-section {
  width: 70%;
  margin-left: 20px;
}

.home-carousel {
  width: 100%;
  height: auto;
  background-color: #f8f9fa;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  overflow: hidden;
}

.patrons-organ-info-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
}

.cro-section {
  flex: 0 0 70%;
  padding: 150px;
  color: #fff;
  max-width: 75%;
  text-align: left;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);
}

.cro-section h1 {
  font-size: 36px;
  margin-bottom: 10px;
}

.cro-section p {
  font-size: 16px;
  line-height: 1.5;
}

.patrons-section {
  flex: 0 0 25%;
  background-color: #fff;
  text-align: center;
  padding: 20px;
  max-width: 25%;
  margin-left: 20px;
  border-radius: 15px;
}

.patrons-header {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: justify;
}

.carousel-patrons {
  width: 70%;
  height: auto;
  max-width: 100%;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.carousel-patrons .carousel-caption {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 5px 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  text-align: center;
  font-size: 14px;
}

.carousel-patrons .carousel-caption h3 {
  margin-bottom: 0;
  font-weight: bold;
}

.fixed-size-img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 15px;
  transition: transform 0.3s ease;
}

.fixed-size-img:hover {
  transform: scale(1.05);
}

.cro-section-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.8;
  z-index: -1;
  border-radius: 15px;
}

.services-section {
  width: 100%;
  background-color: #f8f9fa;
  padding: 50px 20px;
  text-align: center;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.services-section h2 {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 30px;
}

.services-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.service-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 15px;
  margin-bottom: 10px;
}

.service-item {
  width: 100%;
  max-width: 300px;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.service-item:hover {
  transform: translateY(-10px) scale(1.05);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.service-item h3 {
  font-size: 22px;
  margin-bottom: 10px;
}

.service-item p {
  font-size: 16px;
  line-height: 1.5;
}

.weather-info-container {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  background-color: #f0f9f0;
  border: 1px solid #28a745;
  border-radius: 8px;
  color: #2c3e50;
  font-size: 14px;
  font-weight: 500;
  margin: 20px 10px 30px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.weather-info-container::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  height: 10px;
  background: linear-gradient(to bottom, rgba(240, 249, 240, 0), rgba(240, 249, 240, 1));
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.weather-info-container:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.weather-info-title {
  margin-right: 10px;
  font-weight: bold;
  font-size: 16px;
  color: #1b5e20;
}

.weather-info-links {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.weather-info-links a {
  padding: 5px 10px;
  background-color: #fff;
  border: 1px solid #28a745;
  border-radius: 4px;
  color: #28a745;
  text-decoration: none;
  transition: all 0.3s ease, transform 0.3s ease;
  font-size: 14px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.weather-info-links a:hover {
  background-color: #28a745;
  color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transform: translateY(-3px);
}

.news-panel {
  width: 30%;
  height: 40vh;
  margin-left: 20px;
  margin-right: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.news-panel h2 {
  padding: 10px;
  margin: 0;
  background-color: #CC0100;
  color: #ffffff;
  font-size: 20px;
  text-align: center;
}

.news-content-wrapper {
  flex-grow: 1;
  overflow: hidden;
  position: relative;
}

.news-content {
  position: absolute;
  top: 0;
  left: 10px;  /* Add left margin */
  right: 10px; /* Add right margin */
  --scroll-duration: 20s;
  animation: scroll var(--scroll-duration) linear infinite;
}

.news-item {
  padding: 15px;
  margin-bottom: 15px;
  box-sizing: border-box;
  border-bottom: 1px solid #eee;
  background-color: #f9f9f9;
  border-radius: 5px;
  transition: all 0.3s ease; /* Add transition for smooth hover effect */
}

.news-item:last-child {
  margin-bottom: 0;
}

.news-item:hover {
  background-color: #f0f0f0; /* Slightly darker background on hover */
  transform: translateY(-2px); /* Slight lift effect on hover */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Add shadow on hover */
}

.news-item a {
  text-decoration: none;
  color: #333;
  font-size: 14px;
  line-height: 1.4;
  display: block;
}

.news-item a:hover {
  color: #CC0100;
}

@keyframes scroll {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-50%);
  }
}

/* Responsive Styles for Mobile */
@media (max-width: 768px) {
  .carousel-container {
    flex-direction: column;
    align-items: center;
  }

  .carousel-section {
    width: 100%;
    margin-left: 0;
  }

  .patrons-organ-info-container {
    flex-direction: column;
    align-items: center;
  }

  .cro-section {
    width: 100%;
    max-width: 100%;
    padding: 20px;
    margin-bottom: 20px;
  }

  .cro-section h1 {
    font-size: 24px;
  }

  .cro-section p {
    font-size: 14px;
  }

  .patrons-section {
    width: 100%;
    max-width: 100%;
    margin-top: 20px;
  }

  .services-list {
    flex-direction: column;
    align-items: center;
  }

  .service-item {
    width: 100%;
    max-width: 90%;
    margin-bottom: 20px;
  }

  .news-panel {
    width: 100%;
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .news-content {
    left: 5px;  /* Adjust left margin for mobile */
    right: 5px; /* Adjust right margin for mobile */
  }
}
