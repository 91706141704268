
body {
  background-color: white; /* Set the background color to white */
  margin: 0; /* Remove default margin */
  font-family: Arial, sans-serif; /* Optional: Set a default font */
}

header {
  background-color: #007bff; /* Bootstrap primary color */
}

footer {
  background-color: #f8f9fa; /* Bootstrap light color */
  position: relative;
  bottom: 0;
  width: 100%;
}

